@import "~react-image-gallery/styles/css/image-gallery.css";
:root {
  --primary-color: white;
  --secondary-color: #0088a6;
  --blue-lis: #092e3b;
  --blue-lis-ligth:#092e3b82;
  --lis-ligthblue: #00b1e23a;
  --aedas-green: #00b176;
  --aedas-orange:#D1A000;
  --toastify-color-success: #00b176 !important;
  --toastify-color-error: #b1003b !important;
  --aedas-red: #b1003b;
  --font-pay: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  --lis-title: "lis-title";
  --lis-body: "lis-body";
  --iconColor: black;
  --iconButtonSelected: #0088a6;
}

body {
  font-family: var(--lis-title), var(--lis-body);
  background-color: #8080801a !important;
  height: 100%;
}

@font-face {
  font-family: "lis-body";
  src: url(/public/Assets/Fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "lis-title";
  src: url(/public/Assets/Fonts/WorkSans-Medium.ttf);
}

.row {
  margin: 0px !important;
  padding: 0;
}

.row > * {
  padding: 0 !important;
}

main > div {
  height: 100%;
  width: 100%;
}

.MuiDataGrid-root {
  font-family: var(--lis-title);
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.react-tabs {
  height: 100%;
  width: 100%;
}

.react-tabs__tab-panel {
  height: calc(100% - 100px);
}
.modal-backdrop.show {
  z-index: 1201;
}
.modal {
  z-index: 1201 !important;
}
.Toastify__progress-bar--success {
  background: var(--aedas-green) !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.MuiDataGrid-columnHeaders {
  background-color: #092e3b;
  color: white;
}
.MuiDataGrid-columnHeader > .MuiDataGrid-columnSeparator > .MuiSvgIcon-root {
  fill: white !important;
}
.MuiDataGrid-columnHeaderDraggableContainer
  > .MuiDataGrid-menuIcon
  > .MuiButtonBase-root
  > .MuiSvgIcon-root {
  fill: white !important;
}


.MuiDataGrid-cell{
  justify-content: center !important;
}


.MuiDataGrid-iconButtonContainer > .MuiButtonBase-root > .MuiSvgIcon-root{
  fill: white !important;

}

.MuiDataGrid-columnHeaderTitleContainerContent > .MuiCheckbox-root > .MuiSvgIcon-root{
  fill: white !important;
}

.MuiDataGrid-footerContainer .MuiTablePagination-root.css-rtrcn9-MuiTablePagination-root p {
  margin-bottom: 0;
}

.reject_Button {
  margin-top: 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  background-color: white !important;
  font-size: 12px !important;
  font-family: var(--lis-title) !important;
  color: var(--aedas-red) !important;
  border: 2px solid var(--aedas-red) !important;
}

.Button {
  margin-top: 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  background-color: var(--blue-lis) !important;
  font-size: 12px !important;
  font-family: var(--lis-title) !important;
  color: white !important;

}.nextui-c-cKnKee-gbVjbo-color-default {
  --nextui--checkboxColor: var(--blue-lis) !important;
  --nextui--checkboxColorHover: var(--secondary-color) !important;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 99, 99); 
}


.MuiStepLabel-root{
  width: 100px !important;
}

li {
    margin-bottom: 0px !important;

}
.selected >.MuiStepLabel-iconContainer > svg{
  fill:var(--secondary-color) !important;
}
.MuiStepLabel-iconContainer > svg{
  fill:rgb(107, 107, 107) !important;
}

.react-tabs__tab--selected {
  background: transparent !important;
  border-color: transparent !important;
  border-bottom: 4px solid #0088a6 !important;
  border-radius: 0px 0px 0px 0px !important;
}
.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 0;
}
.react-tabs__tab:focus:after {
  background: transparent !important;
}

.react-tabs__tab{
  width: auto !important;
  text-align: center;
  font-family: lis-title, sans-serif;
  font-size: 12px !important;
  padding: 5px 25px;
}

.nextui-input-block-label{
  font-size: 15px !important;
  font-family: var(--lis-title) !important;
}

.rdw-editor-toolbar{
  padding: 10px !important;
  box-shadow: 0px 0px 7px 2px #0000001f !important;
  border-radius: 10px !important;
}

.rdw-option-wrapper{
  border:none !important;
}

.rdw-editor-wrapper{
  height: inherit!important;
}
.rdw-editor-main {
  height: calc(100% - 34px) !important;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 0px 7px 2px #0000001f !important;
  border-radius: 10px;
  margin-top: 10px;
}

.MuiAutoComplete-popper{
  z-index: 10 !important;
  height: 10px !important;
}


.MuiStepper-root{
  width: 600px !important;
}

.progress-bar{
 background-color: var(--aedas-green) !important;
}

.nextui-checkbox-group-label{
  text-align: start !important;
  font-family: "lis-title", sans-serif;
  font-size: 15px !important;
  color:black !important;
} 

.nextui-checkbox-text{
  font-size: 12px !important;
  font-family: 'lis-body' , sans-serif;
}

.nextui-checkbox-label{
  margin-top: 10px !important;
}

.nextui-backdrop-layer-default{
  cursor: auto !important;
}

[role=button]{
  cursor: auto !important;
}

.sup {
  font-size: 0.6em; /* tamaño de fuente más pequeño para el superíndice */
  vertical-align: super; /* eleva el superíndice */
}

.font-family-lis {
  font-family: var(--lis-title);
}