.div-multiple-select div > div {
    border-top: 1px solid #0088a6 !important;
}

.dot-question-in-client-view {
    background: #0088a6;
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 50%;
}