@media screen and (max-width: 1440px) and (min-width: 1099px) {
  .card-client-info {
    display: flex;
    height: 395px !important;
    justify-content: center;
    align-items: center;
  }

  .customer-name {
    font-size: 15px !important;
  }

  .customer-email {
    font-size: 13px !important;
    word-break: break-all;
  }

  .customer-phone {
    font-size: 13px !important;
  }

  .multipleselect-arquetipo .JoyRadioGroup-root {
    gap: 10px;
  }

  .multipleselect-arquetipo .JoySheet-root {
    width: 50px;
    height: 50px;
  }

  .relational-style .MuiAutocomplete-root {
    width: 245px;
  }
}

@media screen and (min-width: 1440px) {
  .card-client-info {
    display: flex;
    height: 395px !important;
    justify-content: center;
    align-items: center;
  }
}
