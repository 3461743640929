.client-view .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 100px) !important;
}

.client-view .image-gallery-slide .image-gallery-image {
  /* object-fit: cover !important; */ /* Este cambio hace que rellene todo el hueco restante pero recorta parte de la imagen, lo hemos quitado porque se han quejado en la SOP-22088 que no se ve el hueco restante de la imagen*/
  aspect-ratio: 0.5;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  top: 0 !important;
  bottom: unset !important;
  padding: 20px !important;
}

@media (max-height: 790px) {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 270px !important;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    max-height: 50px;
  }
}
