@media screen and (max-width: 1400px) {
    .portal-adress-and-house {
        font-size: 22px!important;
    }
}

.react-pdf__message--error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    background: #f4f4f4;
}

/* zIndex para el autocomplete del selector del plano */
.MuiAutocomplete-popper {
    z-index: 99991 !important;
}

.btn-icono-listado-productos > svg:hover {
    fill: var(--secondary-color);
}
