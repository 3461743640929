
@media screen and (max-width: 1440px) and (min-width: 1100px) {
    
    .promotion-info .promotion-dates h1 {
        font-size: 12px!important;
    }
    
    .promotion-info .promotion-dates h2 {
        font-size: 14px!important;
    }

    .promotion-info .promotion-cards h1 {
        font-size: 12px!important;
    }

    .promotion-info .promotion-cards h2 {
        font-size: 15px!important;
    }
}