.client-separator {
    border: 1px solid #fafafa;
}

.questions-and-answers .question { 
    animation-name: aparecer;
    animation-duration: 2s;
}

.questions-and-answers .response {
    font-weight: bold;
    animation-name: aparecer-respuesta;
    animation-duration: 3.5s;
}

.font-bold {
    font-weight: bold;
}

@keyframes aparecer {
    from { opacity: 0; } 
    to   { opacity: 1; } 
}

@keyframes aparecer-respuesta {
    0% { opacity: 0; }
    60% { color: #0088a6; }
    100% { color: initial; opacity: 1;}
}

.client-info {
    animation-name: aparecer-respuesta;
    animation-duration: 3.5s;
}

.client-view .image-gallery-play-button {
    display: none;
}

.client-view  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 50px;
}

.hover-pointer:hover {
    cursor: pointer;
}