.bg-thanks{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/Assets/img/promociond.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.thanks-text {  
  font-weight: bold;
  font-size: 35px;
}