@media screen and (max-width: 1280px) {
    .cliente-debe-rellenar .icon { 
        display: none;
    }

    .cliente-debe-rellenar .requirement-text { 
        font-size: 14px;
    }
    
}
