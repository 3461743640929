.modalContainer {
  width: 95%;
  margin: 0 auto;
}

.headerContainer {
  width: 100%;
  text-align: end;
}

.closeButton {
  cursor: pointer;
  margin-top: 1rem;
  color: var(--aedas-grey);
}

.modalTitle {
  text-transform: capitalize;
}

.bodyContainer {
  max-height: calc(
    90vh - 12rem
  ); /*La altura del modal + 6rem del header y 6rem del footer*/
}

.largeBodyContainer {
  overflow-y: scroll;
}

.largeBodyContainer::-webkit-scrollbar {
  width: 3px;
  background-color: var(--aedas-lightgrey);
}

.largeBodyContainer::-webkit-scrollbar-thumb {
  background: var(--aedas-grey);
}

.footerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 1rem auto 0;
  padding-bottom: 3rem;
}
