
.intput_container  {
    width: 260px;
}
.intput_container_client  {
    width: 100%;
}


.JoySheet-root  {
    border-top: 1px solid #0088a6 !important;
}

