.background-editable {
    background-color: #c5edf9;
}


.link-open-cliente-window {
    font-size: 14.5px;
    margin-top: 5px;
}

.link-open-cliente-window:hover {
    cursor: pointer;
}

@media screen and (max-width: 1440px) {
    .link-open-cliente-window {
        font-size: 12px !important;        
    }    
}

/* Altura del modal de Listado de viviendas */
#ModalAvailableProduct_in_seller_tsx {
    padding-bottom: 50px;
    max-height: calc(-50px + 100vh);
}

/* En el modal de viv. disponibles, a partir de 1319px que solo haya 3 columnas */
@media screen and (min-width: 1319px) {
    .grid-listado-viviendas {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 290px))!important;
    }
}

.btn-presupuesto, .btn-calendario {
    padding: 3px 3px;
    cursor: pointer;
}

.btn-presupuesto:hover, .btn-calendario:hover {
    background: #f4f4f4;
}

.highlighted {
    border-bottom: 4px solid #0088a6;
    background: #c2eaf6;
}

@media screen and (max-width: 1200px) { 
    .btn-presupuesto svg, .btn-calendario svg {
        display: none;
    }
}

/* Control de los tabs: Hasta 1300px "solo" ocupa 101px */
@media screen and (max-width: 1300px) { 
    .otras-caracteristicas {
        font-size: 15px!important;
    }
}

.seller-form-tabs h3 {
    font-size: 22px;
}


/* Control del titulo de los desplegables: Hasta 1400px -> 18px */
@media screen and (max-width: 1400px) {
    .seller-form-tabs h3 {
        font-size: 18px!important;
    }
}

.nextui-c-hPzDAx {
    margin: 5px 0px !important;
}



.promotion-info-in-seller .react-tabs__tab-panel {
    height: calc(100% - 10px);
  }