.imgPersonHeader {
  height: 70px;
  width: 70px;
  border-radius: 100%;
}

.personHeader {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.openCloseButton_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.imgPersonHeaderSmall {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}


.appName{
  font-size: 20px;
    font-family: var(--lis-title);
    font-weight: bold;
    vertical-align: middle;
    height: 10px;
}